import { createSlice } from "@reduxjs/toolkit";
import api from "../apis";

const userSlice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    error: false,
    success: false,
    message: "",
    users: [],
  },
  reducers: {},
  extraReducers: (build) => {
    build.addCase(api.allUsers.pending, (state) => {
      state.loading = true;
    });

    build.addCase(api.allUsers.fulfilled, (state, action) => {
      console.log({ action });
      state.loading = false;
      state.success = true;
      state.message = action?.payload?.message;
      state.users = action?.payload?.data;
    });

    build.addCase(api.allUsers.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = action?.payload?.error?.message;
    });
    build.addCase(api.resetAll, (state) => {
      state.error = false;
      state.message = "";
    });
  },
});

export default userSlice.reducer;
