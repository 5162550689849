import React from "react";
import { Link } from "react-router-dom";

const LinkComponent = ({ route, children, color, textDecoration, onClick }) => {
  return (
    <Link
      to={route}
      style={{ textDecoration: textDecoration, color: color }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default LinkComponent;
