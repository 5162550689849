import { configureStore } from "@reduxjs/toolkit";
import login from "./reducers/login";
import transactions from "./reducers/transactions";
import banks from "./reducers/banks";
import users from "./reducers/users";

export const store = configureStore({
  reducer: {
    login: login,
    transaction: transactions,
    banks: banks,
    users: users,
  },
});
