import { tokens } from "../theme";
import { useTheme, SwipeableDrawer } from "@mui/material";

export default function SideDrawer({ open, toggleDrawer, children }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: colors.primary[400],
          px: 6,
        },
      }}
    >
      {/* <CreateUser/> */}
      {children}
    </SwipeableDrawer>
  );
}
