import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import apis from "../../store/apis";
import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import SideDrawer from "../../hoc/sideDrawer";
import Loader from "../../components/Loader";
import { styled } from "@mui/material";
import { Formik } from "formik";

const Banks = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { loading, banks, createLoading } = useSelector((state) => state.banks);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  useEffect(() => {
    dispatch(apis.allBanks());
  }, [dispatch]);

  const columns = [
    // { field: "_id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "shortName",
      headerName: "Short name",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "swiftCode",
      headerName: "Swift Code",
      flex: 1,
      // renderCell: (params) => (
      //   <Typography color={colors.greenAccent[500]}>
      //     ${params.row.cost}
      //   </Typography>
      // ),
    },
    // {
    //   field: "date",
    //   headerName: "CreatedAt",
    //   flex: 1,
    // },
  ];

  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.mode === "dark" ? "#E0E3E7" : "#70d8bd",
      },
      "&:hover fieldset": {
        borderColor: "#B2BAC2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#70d8bd",
      },
    },
  }));

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="BANKS" subtitle="List of Banks we cooperate with" />
        <Box>
          <Button
            onClick={toggleDrawer(true)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Add sx={{ mr: "10px" }} />
            Add new
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={banks}
          columns={columns}
          getRowId={(row) => row._id}
          loading={loading}
        />
      </Box>
      <SideDrawer open={open} toggleDrawer={toggleDrawer}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "calc(100vh - 120px)",
            maxWidth: 400,
            // px: 26,
          }}
        >
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 20px 0" }}
          >
            Add new Bank
          </Typography>
          <Formik
            initialValues={{
              name: "",
              shortName: "",
              country: "",
              swiftCode: "",
            }}
            // validationSchema={loginValidations}
            onSubmit={(values, { setSubmitting }) => {
              // handling login request
              dispatch(apis.createBanks(values));
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <CustomTextField
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Bank name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                />
                <CustomTextField
                  error={Boolean(errors.shortName)}
                  margin="normal"
                  required
                  fullWidth
                  name="shortName"
                  label="Bank abbreviation"
                  id="outlined-basic"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shortName}
                  helperText={errors.shortName}
                  sx={{ my: 3 }}
                  size="small"
                />
                <CustomTextField
                  error={Boolean(errors.country)}
                  margin="normal"
                  required
                  fullWidth
                  name="country"
                  label="Country"
                  id="outlined-basic"
                  autoComplete="country"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                  helperText={errors.country}
                  sx={{ mb: 3 }}
                  size="small"
                />
                <CustomTextField
                  error={Boolean(errors.swiftCode)}
                  margin="normal"
                  required
                  fullWidth
                  name="swiftCode"
                  label="Swift Code"
                  id="outlined-basic"
                  autoComplete="swiftCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.swiftCode}
                  helperText={errors.swiftCode}
                  sx={{ mb: 3 }}
                  size="small"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ my: 3 }}
                  disabled={createLoading}
                >
                  {createLoading ? <Loader color="#fff" /> : "Submit"}
                </Button>
              </Box>
            )}
          </Formik>
        </Box>
      </SideDrawer>
    </Box>
  );
};

export default Banks;
