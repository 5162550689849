import { createSlice } from "@reduxjs/toolkit";
import api from "../apis";

const bankSlice = createSlice({
  name: "banks",
  initialState: {
    loading: false,
    createLoading: false,
    error: false,
    success: false,
    message: "",
    banks: [],
    bank: {},
  },
  reducers: {},
  extraReducers: (build) => {
    build.addCase(api.allBanks.pending, (state) => {
      state.loading = true;
    });

    build.addCase(api.allBanks.fulfilled, (state, action) => {
      console.log({ action });
      state.loading = false;
      // state.success = true;
      // state.message = action?.payload?.message;
      state.banks = action?.payload?.data;
    });

    build.addCase(api.allBanks.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = action?.payload?.error?.message;
    });

    build.addCase(api.createBanks.pending, (state) => {
      state.createLoading = true;
    });

    build.addCase(api.createBanks.fulfilled, (state, action) => {
      state.createLoading = false;
      state.success = true;
      state.bank = action.payload.data;
      state.error = false;
      state.banks.push(action.payload.data);
    });

    build.addCase(api.createBanks.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = action?.payload?.error?.message;
    });

    build.addCase(api.resetAll, (state) => {
      state.error = false;
      state.message = "";
    });
  },
});

export default bankSlice.reducer;
