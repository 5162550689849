import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../pages/global/Sidebar";
import Topbar from "../pages/global/Topbar";

export default function DashboardLayout() {
  return (
    <div className="admin-layout">
      <Sidebar />
      <main className="content">
        <Topbar />
        <div className="main-content">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
