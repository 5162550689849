import React from "react";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
const Alert = function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
};

const CustomSnackBar = ({ type, openSnackBar, message }) => {
  return (
    <Stack>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert severity={type}>{message}</Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomSnackBar;
