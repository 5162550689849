import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

class Api {
  resetAll = createAction("resetAll");

  // API FOR AUTHENTICATION
  login = createAsyncThunk("login", async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/auth/login`, { ...data });
      this.setAuthorisation(response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error?.response?.data });
    }
  });
  // API FOR AUTHENTICATION
  transactions = createAsyncThunk(
    "transactions",
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(`/transactions/all`);
        // console.log(JSON.stringify({ response }, null, 2));
        return response.data;
      } catch (error) {
        return rejectWithValue({ error: error?.response?.data });
      }
    }
  );

  allBanks = createAsyncThunk("banks", async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/banks/all`);
      // console.log(JSON.stringify({ response }, null, 2));
      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error?.response?.data });
    }
  });

  createBanks = createAsyncThunk(
    "createBank",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post(`/banks/create`, data);
        // console.log(JSON.stringify({ response }, null, 2));
        return response.data;
      } catch (error) {
        return rejectWithValue({ error: error?.response?.data });
      }
    }
  );

  allUsers = createAsyncThunk("users", async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/users`);
      // console.log(JSON.stringify({ response }, null, 2));
      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error?.response?.data });
    }
  });

  setAuthorisation = (token) => {
    localStorage.setItem("x-token", token);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token,
    };
  };
}

const apis = new Api();
export default apis;
