/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Banks from "./pages/banks";
import Transactions from "./pages/transactions";
import Form from "./pages/form";
import FAQ from "./pages/faq";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./pages/calendar/calendar";
import DashboardLayout from "./hoc/adminLayout";
import Login from "./pages/login";
import { store } from "./store";
import { jwtDecode } from "jwt-decode";
import apis from "./store/apis";

const token = localStorage.getItem("x-token");

function App() {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate();

  const chechInitialAuth = useCallback(async () => {
    try {
      if (!token && window.location.pathname !== "/") {
        navigate("/", {});
        return;
      }
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        // handle logout
        if (!token && window.location.pathname !== "/") {
          navigate("/", {});
          return;
        }
      } else {
        apis.setAuthorisation(token);
        navigate("/dashboard", {});
      }
    } catch (error) {
      console.log({ error });
      // handle logout
      if (window.location.pathname !== "/") {
        navigate("/", {});
      }
    }
  }, []);

  useEffect(() => {
    chechInitialAuth();
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="/dashboard/team" element={<Users />} />
              <Route
                path="/dashboard/transactions"
                element={<Transactions />}
              />
              <Route path="/dashboard/invoices" element={<Banks />} />
              <Route path="/dashboard/form" element={<Form />} />
              <Route path="/dashboard/faq" element={<FAQ />} />
              <Route path="/dashboard/calendar" element={<Calendar />} />
            </Route>
          </Routes>
        </Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
