import React, { useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import LinkComponent from "../../components/link";
import Copyright from "../../components/copyright";
import loginValidations from "../../utils/login-validations";
import CustomSnackBar from "../../components/snack-bar";
import { useTheme, IconButton } from "@mui/material";
import api from "../../store/apis";
// import { useNavigate } from "react-router-dom";
import { tokens, ColorModeContext } from "../../theme";
import { styled } from "@mui/material";
import Loader from "../../components/Loader";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.mode === "dark" ? "#E0E3E7" : "#2089dc",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2089dc",
    },
  },
}));

export default function Login() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const { loading, error, success } = useSelector((state) => state.login);

  React.useEffect(() => {
    if (success) {
      navigation("/dashboard");
    }
    if (error) {
      // handleClickSnackBar();
      setTimeout(() => {
        dispatch(api.resetAll());
        setOpenSnackBar(false);
      }, 2000);
    }
  }, [error, dispatch, success, navigation]);

  return (
    <Box display="flex" width="100%">
      <Typography
        component="div"
        sx={{
          mx: 4,
          width: "35%",
        }}
      >
        <Box display="flex" justifyContent="end" mt="20px">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "calc(100vh - 120px)",
          }}
        >
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 20px 0" }}
          >
            Login
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="profile-user"
              width="100px"
              height="100px"
              src={`../../assets/user.png`}
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          </Box>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginValidations}
            onSubmit={(values, { setSubmitting }) => {
              // handling login request
              dispatch(
                api.login({
                  email: values.email,
                  password: values.password,
                })
              );
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <CustomTextField
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <CustomTextField
                  error={Boolean(errors.password)}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="outlined-basic"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pin}
                  helperText={errors.pin}
                  sx={{ mb: 3 }}
                />
                <LinkComponent
                  route="/forgot-pin"
                  color={colors.greenAccent[400]}
                  textDecoration="none"
                >
                  Forgot password?
                </LinkComponent>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ my: 3 }}
                  disabled={isSubmitting}
                >
                  {loading ? <Loader color="#fff" /> : "Login"}
                </Button>
              </Box>
            )}
          </Formik>
        </Box>
        <Copyright />
        <CustomSnackBar
          type={"error"}
          openSnackBar={openSnackBar}
          // message={message}
        />
      </Typography>
      <Box
        width="65%"
        height="100vh"
        sx={{
          backgroundImage: "url(/assets/login-background.jpeg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Typography
          component="div"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: colors.primary[400],
            opacity: 0.6,
          }}
        ></Typography>
      </Box>
    </Box>
  );
}
