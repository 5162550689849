import { createSlice } from "@reduxjs/toolkit";
import api from "../apis";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    error: false,
    success: false,
    message: "",
    response: {},
  },
  reducers: {},
  extraReducers: (build) => {
    build.addCase(api.login.pending, (state) => {
      state.loading = true;
    });

    build.addCase(api.login.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action?.payload?.data?.message;
      state.response = action?.payload?.data;
    });

    build.addCase(api.login.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = action?.payload?.error?.message;
    });
    build.addCase(api.resetAll, (state) => {
      state.error = false;
      state.message = "";
    });
  },
});

export default loginSlice.reducer;
