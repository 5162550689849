import axios from "axios";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKE_END_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("x-token"),
  },
});

export default axiosInstance;
