import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

export const appColors = {
  primary: "#2089dc",
  secondary: "#439ce0",
  sharp_primary: "#61cee8",
  tertiary: "#f2f2f2",
  white: "#FFFFFF",
  black: "#0D0D0D",
  grey: "#bdc6cf",
  disabled: "hsl(208, 8%, 90%)",
  danger: "#FF0000",
  shadowColor: "#000",
  formBg: "#F8F8FF",
};

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
      grey: {
        '100': '#eaecef',
        '200': '#d9dee4',
        '300': '#bdc6cf',
        '400': '#a0adba',
        '500': '#8996a8',
        '600': '#788398',
        '700': '#6b748a',
        '800': '#5b6172',
        '900': '#31333a',

      },
      primary: {
        100: "#d0d1d5",
        200: "#a1a4ab",
        300: "#727681",
        400: "#1F2A40",
        500: "#141b2d",
        600: "#101624",
        700: "#0c101b",
        800: "#080b12",
        900: "#040509",
      },
      greenAccent: {
        '100': '#e2effc',
        '200': '#bfddf8',
        '300': '#87c1f2',
        '400': '#47a2e9',
        '500': '#2089dc',
        '600': '#1269b7',
        '700': '#105494',
        '800': '#11487b',
        '900': '#0d2744',
      },
      redAccent: {
        '100': '#ffdddd',
        '200': '#ffc0c0',
        '300': '#ff9494',
        '400': '#ff5757',
        '500': '#ff2323',
        '600': '#ff0000',
        '700': '#d70000',
        '800': '#b10303',
        '900': '#500000',
      },
      blueAccent: {
        '100': '#e2effc',
        '200': '#bfddf8',
        '300': '#87c1f2',
        '400': '#47a2e9',
        '500': '#2089dc',
        '600': '#1269b7',
        '700': '#105494',
        '800': '#11487b',
        '900': '#0d2744',
      },
    }
    : {
      grey: {
        '900': '#eaecef',
        '800': '#d9dee4',
        '700': '#bdc6cf',
        '600': '#a0adba',
        '500': '#8996a8',
        '400': '#788398',
        '300': '#6b748a',
        '200': '#5b6172',
        '100': '#31333a',
      },
      primary: {
        100: "#040509",
        200: "#080b12",
        300: "#0c101b",
        400: "#f2f0f0", // manually changed
        500: "#141b2d",
        600: "#1F2A40",
        700: "#727681",
        800: "#a1a4ab",
        900: "#d0d1d5",
      },
      greenAccent: {
        '100': '#e2effc',
        '200': '#bfddf8',
        '300': '#87c1f2',
        '400': '#47a2e9',
        '500': '#2089dc',
        '600': '#1269b7',
        '700': '#105494',
        '800': '#11487b',
        '900': '#0d2744',
      },
      redAccent: {
        '900': '#ffdddd',
        '800': '#ffc0c0',
        '700': '#ff9494',
        '600': '#ff5757',
        '500': '#ff2323',
        '400': '#ff0000',
        '300': '#d70000',
        '200': '#b10303',
        '100': '#500000',
      },
      blueAccent: {
        '900': '#e2effc',
        '800': '#bfddf8',
        '700': '#87c1f2',
        '600': '#47a2e9',
        '500': '#2089dc',
        '400': '#1269b7',
        '300': '#105494',
        '200': '#11487b',
        '100': '#0d2744',
      },
    }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.greenAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: colors.primary[500],
          },
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[100],
          },
          secondary: {
            main: colors.greenAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: "#fcfcfc",
          },
        }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
