import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataContacts } from "../../data/mockData";
import GppGoodIcon from "@mui/icons-material/GppGoodOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import apis from "../../store/apis";

const moneyFomatter = (amount) => new Intl.NumberFormat().format(amount);

const Transactions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const { loading, transactions } = useSelector((state) => state.transaction);

  useEffect(() => {
    dispatch(apis.transactions());
  }, [dispatch]);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: ({ row: { createdAt } }) => {
        return (
          <Typography sx={{}}>
            {createdAt.split("T")[0]} {createdAt.split("T")[1].split(".")[0]}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Sender Names",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.userId.firstName} {row.userId.lastName}
          </Typography>
        );
      },
    },
    {
      field: "phone",
      headerName: "Sender country",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.userId.country}</Typography>;
      },
    },
    {
      field: "names",
      headerName: "Receiver Names",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.receiverDetails.firstName} {row.receiverDetails.lastName}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.receiverDetails.email}</Typography>;
      },
    },
    {
      field: "destination",
      headerName: "Destination",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {moneyFomatter(row.amount)} {row.senderCurrency}
          </Typography>
        );
      },
    },
    {
      field: "amountSent",
      headerName: "Net",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {moneyFomatter(row.amountSent)} {row.receiverCurrency}
          </Typography>
        );
      },
    },
    {
      field: "fare",
      headerName: "Commition",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {moneyFomatter(row.fare)} {row.senderCurrency}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: ({ row: { status } }) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              p: 0.4,
              px: 1,
              borderRadius: 2,
              backgroundColor:
                status === "Approved"
                  ? colors.greenAccent[500]
                  : status === "Failed"
                  ? colors.redAccent[400]
                  : colors.blueAccent[500],
            }}
          >
            {status === "Approved" ? (
              <GppGoodIcon />
            ) : status === "Pending" ? (
              <PublishedWithChangesOutlinedIcon fontSize="small" />
            ) : (
              <CancelOutlinedIcon />
            )}
            <Typography marginLeft={0.5}>{status}</Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="TRANSACTIONS" subtitle="List of Transactions made" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={transactions}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
          density="compact"
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Transactions;
